





























import Axios from "axios";
import { Component, Vue, Prop } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";

@Component({
  components: {
  },
})
export default class ChangeAssignedProcessorComponent extends Vue {

  @Prop({ required: true })
  public loanTxnId: String;

  @Prop({ required: true })
  public roleId: String;

  @Prop({default: false})
  public loanIsLocked: boolean;

  public processors: Array<object> = [];
  public selectedProcessorId: String = null;
  public selectedPreviousProcessorId: String = null;

  get isSupervisor() {
    return this.processors.length > 1;
  }

  public async getWemloProcessorList() {
    try {
      this.$store.state.wemloLoader = true;

      let response = await Axios.get(BASE_API_URL + "wemloprocessor/getAllWemloProcessorList");

      this.processors = response.data.wemloStaffList;
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  public async getAssignedProcessor() {
    try {
      this.$store.state.wemloLoader = true;

      let response = await Axios.get(BASE_API_URL + "wemloprocessor/getAssignedProcessor",
        {
          params: {
            loanTxnId: this.loanTxnId,
            roleId: this.roleId,
          }
        }
      );

      this.selectedProcessorId = response.data.processor.userId;
      this.selectedPreviousProcessorId = response.data.processor.userId;
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  public async changeAssignedProcessor() {
    if (!this.selectedProcessorId) {
      this.$snotify.error("Please select Processor ");
      return;
    }

    if (this.selectedProcessorId === this.selectedPreviousProcessorId) {
      this.$snotify.error("Please select other Processor");
      return;
    }

    try {
      this.$store.state.wemloLoader = true;

      let response = await Axios.post(BASE_API_URL + "wemloprocessor/changeAssignedProcessor",
        {
          isProcessorOfRecord: true,
          selectedProcessorId: this.selectedProcessorId,
          selectedPreviousProcessorId: this.selectedPreviousProcessorId,
          loanTxnId: this.loanTxnId,
          roleId: this.roleId,
        }
      );
      
      this.selectedPreviousProcessorId = this.selectedProcessorId;

      this.$snotify.success(response.data.message);
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  async mounted() {
    await this.getAssignedProcessor();
    await this.getWemloProcessorList();
  }
}
