

import { Component } from "vue-property-decorator";
import { BASE_API_URL, EventBus } from "@/config";
import Axios from "axios";

import {CustomTask} from "@/models/tasks.model";
import { Device } from "twilio-client";
import stores from "@/store/store";
import DisplayFiles from "@/views/DisplayDocument.vue"
import _ from 'lodash';

import PipeLineSocketMixin from "@/mixins/PipeLineSocketMixin"
import { mixins } from "vue-class-component";

const store: any = stores;
@Component({components:{DisplayFiles}})
export default class CustomTaskComponent extends mixins(PipeLineSocketMixin) {
  public customTaskData = new CustomTask();
  public pipeLineHeaderData: any = {};
  public loanTxnId = null;
  public callingStatus = null;
  public callInProgress = null
  public borrowerInfo =null
  public wemloNumber =null
  public customId =null
  public taskAssigner = '';
  public taskNameForRoom = 'custom-task';

   private playWeLearnVideo() {
    EventBus.$emit("openWeLearn", { taskName: "Custom Task", videoUrl: null });
  }

  get twilioActiveStatus() {
    return this.$store.state.twilioActiveStatus;
  }
  public async submit(ifSave) {
    try {
      ifSave = ifSave == "Save" ? true : false;
     this.$store.state.wemloLoader = true;
      if (ifSave ? false : !(await this.$validator.validateAll("comment")))
        return

      let formData = new FormData();

      formData.append("customTaskData", JSON.stringify(this.customTaskData));
      formData.append("loanTxnId",this.loanTxnId)
      formData.append("index",JSON.stringify(this.$route.query.identifier))
      formData.append("ifSave", JSON.stringify(ifSave));
      let response = await Axios.post(
        BASE_API_URL + "additionalTask/submitCustomTask",
        formData);
        this.$snotify[
        response.data.status == 200
          ? "success"
          : response.data.status == 403
          ? "info"
          : "error"
      ](response.data.message);

      if (response.data.status == 200) {
        this.saveEvent();
      }
        
      EventBus.$emit('closeWeLearn',false); // Closing learn more video modal;

      if (!ifSave) {
        this.$router.push({ path: "dashboard" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  public async fetchCustomTaskDetail(){
      try {
        this.$store.state.wemloLoader = true;
          let response =await Axios.post(
              BASE_API_URL + "additionalTask/fetchCustomTaskDetail",{
              loanTxnId :this.loanTxnId,
              index:this.$route.query.identifier
              },{}
          )
        this.pipeLineHeaderData = response.data.pipeLineHeaderData;
        this.customTaskData = response.data.customTaskData
        this.wemloNumber = response.data.number.wemloNumber;
        this.customId = response.data.customTaskData._id
       this.borrowerInfo = response.data.borrowerInfo
         this.$store.state.wemloLoader = false;
      } catch (error) {
          console.log(error)
           this.$store.state.wemloLoader = false;
      }
  }

/**
 *displayDocument
 */
public displayDocuments(files){
if (files[0].hasOwnProperty("path")) {
        this.$refs.dfv["setInitialValuesForShowingSamplesWithDeleteButton"](
          // "View Document",
          files, false,null
        );
      }
} 
  /*****Calling function  */
  public async disconnectCall() {
    try {
      Device.disconnectAll();
    } catch (error) {
      console.log(error.response);
    }
  }

  public getCallButtonTitle() {
    if (this.callingStatus == "Cannot connect now.") {
      return "Call cannot be connected now.Please try again later.";
    } else if (
      this.callingStatus == "Connecting..." ||
      this.callingStatus == "Dialing..."
    ) {
      return "Call in Progress.";
    } else {
      return null;
    }
  }
   public async callToCustomer() {
    try {
      this.callingStatus = "Dialing...";
      let tempCallId = Math.floor(Math.random() * 100000000000000000);
      var params = {
        wemloNumber: this.wemloNumber,
        tempCallId: tempCallId,
        phoneNumber:this.borrowerInfo.personalInfo.contactInformation.cellPhone,
        fromUserId: this.$userId,
        fromType: "Wemlo Processor",
        toUserType: "Borrower",
        toUserId: this.borrowerInfo.userId,
        loanTxnId: this.loanTxnId,
        moduleInfo: "customTask",
        moduleId: this.customId
      };
      await Device.connect(params);
      await Device.on("connect", connection => {
        this.callInProgress = true;
        this.callingStatus = "In call with " + connection.message.phoneNumber;
      });
      await Device.on("error", e => {
        this.callingStatus = "Cannot connect now.";
        this.callInProgress = false;
      });
      await Device.on("disconnect", async d => {
        this.callingStatus = "Disconnected";
        this.callInProgress = false;
        setTimeout(() => {
          this.callingStatus = "Ready";
        }, 200);
        await this.saveAndGetCallDetails(tempCallId);
      });
    } catch (error) {
      console.log(error);
    }
  }
    public async saveAndGetCallDetails(tempCallId) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "communication/saveAndGetCallDetails",
        {
          tempCallId: tempCallId,
          fromUserId: this.$userId,
          fromType: "Wemlo Processor",
          toUserType: "Borrower",
          toUserId: this.borrowerInfo.userId,
          loanTxnId: this.pipeLineHeaderData.loanTxnId
        });
    } catch (error) {
      console.log(error.response);
    }
  }

  public async getTaskAssignerName() {
    try {
      let taskAssignerInfo = await Axios.post(
        BASE_API_URL + "common/getUserInfo",
        {
          userType: "Wemlo Processor",
          userId: this.customTaskData.addedBy
        });
      if(!taskAssignerInfo.data) return;
      this.taskAssigner = _.get(taskAssignerInfo.data, 'firstName', '') + ' ' + _.get(taskAssignerInfo.data, 'lastName', '');
    } catch (error) {
      console.log(error.response);
    }
  }

  async mounted() {
    this.loanTxnId = this.$route.query.id
      this.callingStatus = this.twilioActiveStatus;
    Device.on("ready", a => {
      this.$store.dispatch("setTwilioActiveStatus", "Ready");
    });
    Device.on("error", e => {
      this.$store.dispatch("setTwilioActiveStatus", "Cannot connect now.");
    });
    await this.fetchCustomTaskDetail()
    await this.getTaskAssignerName();
  }
  
}
