

import { Component } from "vue-property-decorator";
import AddComment from "@/views/AddComment.vue";
import { VoeResponse } from "@/models/tasks.model";
import { BASE_API_URL,EventBus } from "../../../../../config";
import Axios from "axios";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";
import DisplayFiles from "@/views/DisplayDocument.vue";
import PipeLineSocketMixin from "@/mixins/PipeLineSocketMixin"
import CommonMixin from "@/mixins/common.mixin";
import { mixins } from "vue-class-component";

@Component({ components: { AddComment,DisplayFiles } })
export default class VoiResponseComponent extends mixins(PipeLineSocketMixin, CommonMixin) {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public voeResponse = new VoeResponse();
  public pipeLineHeaderData: any = {};
  public loanTxnId = null;
  public errorMessage = false;
  public taskNameForRoom = 'voe-response';

   private playWeLearnVideo() {
    EventBus.$emit("openWeLearn", { taskName: "Voe Response", videoUrl: 'https://wemlo.s3.amazonaws.com/Common/taskdemo/VOEResponse.mp4' });
  }
  public async fetchVoeResponseTaskDetail() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "additionalTask/fetchVoeResponseTaskDetail",
        { loanTxnId: this.$route.query.id });
      this.pipeLineHeaderData = response.data.pipeLineHeaderData;
      this.voeResponse = response.data.voeResponse;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }

  public async submit(ifSave) {
    try {
      ifSave = ifSave == "Save" ? true : false;
      let validComment = await this.$refs.comment["validateComment"](ifSave);

      if (
        (ifSave ? false : !(await this.$validator.validateAll())) ||
        !validComment
      )
        return;
      this.$store.state.wemloLoader = true;
      let formData = new FormData();

      formData.append("loanTxnId", this.loanTxnId);

      /**
       * Appending comment data and comment attachments here in formdata
       */
      let commentObj: any = {
        comment: this.voeResponse.commentData.comment,
        showCommentToBroker: this.voeResponse.commentData.showCommentToBroker
      };
      if (
        this.voeResponse.commentData.commentAttachment.length > 0 &&
        !this.voeResponse.commentData.commentAttachment[0].hasOwnProperty(
          "path"
        )
      )
        this.voeResponse.commentData.commentAttachment.forEach(file => {
          formData.append("commentAttachment", file);
        });
      else if (this.voeResponse.commentData.commentAttachment.length > 0)
        commentObj.commentAttachment = this.voeResponse.commentData.commentAttachment;
      formData.append("commentData", JSON.stringify(commentObj));

      /**
       * Merging the save or complete option here with wemlo invoice and options
       */
      formData.append("ifSave", ifSave);
      formData.append("options", JSON.stringify(this.voeResponse.options));
      if(this.voeResponse.uploadSignedFile){
         this.voeResponse.uploadSignedFile.forEach(file => {
          formData.append("uploadSignedFile", file);
        });
      }
      let response = await Axios.post(
        BASE_API_URL + "additionalTask/voeResponse",
        formData);

      this.$snotify[
        response.data.status == 200
          ? "success"
          : response.data.status == 403
          ? "info"
          : "error"
      ](response.data.message);

      if (response.data.status == 200) {
        this.saveEvent();
      }

      EventBus.$emit('closeWeLearn',false); // Closing learn more video modal;

      if (!ifSave) {
        this.$router.push({ path: "dashboard" });
      }
    } catch (error) {
      console.log(error);
      const infectedFiles = this.checkResponseIfFileInfected(error);
      infectedFiles.forEach(filename => {
        this.removeInfectedFile(filename)
      })
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }
  public removeInfectedFile(filename: string): void {
    // check if comment attachment contains infected filename
    if (
      this.voeResponse.commentData.commentAttachment.length > 0 &&
      !this.voeResponse.commentData.commentAttachment[0].hasOwnProperty("path")
    )
    {
      this.voeResponse.commentData.commentAttachment = this.voeResponse.commentData.commentAttachment.filter(file => (file.name !== filename));
    }
    if (
      this.voeResponse.uploadSignedFile &&
      !this.voeResponse.uploadSignedFile.hasOwnProperty("path")
    ) {
      this.voeResponse.uploadSignedFile = this.voeResponse.uploadSignedFile.filter(file => (file.name !== filename));
    }
  }

  public async resendEmailToVoe() {
    try {
      let loanTxnId = this.$route.query.id;
      let response = await Axios.post(
        BASE_API_URL + "additionalTask/resentEmailToVoe",
        { loanTxnId: this.$route.query.id });
      this.$snotify.success("Email sent successfully");
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * To upload appraisal report invoice
   */
  public uploadSignedVOEResponse(event) {
    try {
       if (event.target.files.length > 0) {
      Object.values(event.target.files).forEach(element => {
      this.voeResponse.uploadSignedFile.push(element)
    })
    }
    } catch (error) {
      console.log(error);
    }
  }


   /**
   * To display  appraisal report invoice file
   */
  public viewuploadSignedFile(file,fileName) {
      this.$refs.comment["displayCommentAttachment"](file, true,fileName);
  }
  public deleteDoc(obj){
     this.voeResponse[obj.deleteData].splice(obj.index,1)
  }
public async  deleteFile(obj){
    let index = this.voeResponse[obj.deleteData].findIndex(
      doc => doc.path == obj.files
    );
  this.voeResponse[obj.deleteData].splice(index,1)
      await this.deleteDisplayFromTaskCollection(index,obj.deleteData);
}
 public async deleteDisplayFromTaskCollection(index, categoryName) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/deleteDocumentByCategoryName",
        { loanTxnId: this.loanTxnId, index: index, categoryName: categoryName });
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    this.loanTxnId = this.$route.query.id;
    await this.fetchVoeResponseTaskDetail();
  }
}
