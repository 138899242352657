



















































































































































































































import {
    Component,
    Vue,
    Prop,
    Watch
} from "vue-property-decorator";
import {
    BASE_API_URL
} from "@/config";
import DisplayFiles from "@/views/DisplayDocument.vue";
import Axios from "axios";
import {
    borrowerCondition,
    thirdPartyCondition
} from '@/models/tasks.model';

import { ALLOWED_FILE_EXTENSIONS } from "@/constants";

@Component({
    components: {
        DisplayFiles
    }
})
export default class AddConditions extends Vue {
    public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
    @Prop()
    public borrowerCondition;
    @Prop({default: false})
    public loanIsLocked: boolean;
    @Prop()
    public thirdPartyCondition;

    public ifSave: Boolean = null;

    @Watch('borrowerCondition')
    checkBorrowerCondition() {
        if (this.borrowerCondition.length == 0) this.addBorrowerCondition();
    }
    @Watch('thirdPartyCondition')
    checkThirdPartyCondition() {
        if (this.thirdPartyCondition.length == 0) this.addThirdPartyCondition();
    }


    public async addBorrowerCondition() {
        try {
            let obj = new borrowerCondition();
            this.borrowerCondition.push(obj);
        } catch (error) {
            console.log(error);
        }
    }

    public async addThirdPartyCondition() {
        try {
            let obj = new thirdPartyCondition();
            this.thirdPartyCondition.push(obj);
        } catch (error) {
            console.log(error);
        }
    }

    public async removeCondition(removeFrom, index) {
        try {
            this[removeFrom].splice(index, 1);
        } catch (error) {
            console.log(error);
        }
    }

    public async displaySamples(samples, conditionName, docFromData) {
        try {
            if (samples[0].hasOwnProperty("path")) {
                this.$refs.dfc["setInitialValuesForShowingSamplesWithHeading"](
                    "View Document",
                    samples
                );
            } else {
                this.$refs.dfc["setInitialValuesForLocalFiles"](samples, true, docFromData);
            }
        } catch (error) {
            console.log(error);
        }
    }

    private deleteLocalSample(data) {
        try {
            this[data.deleteData.location][data.deleteData.index].samples.splice(data.index, 1)
        } catch (error) {
            console.log(error);
        }
    }

    public async uploadPendingConditionSample(event, uploadTo, index) {
        try {
            if (event.target.files.length > 0) {
                this[uploadTo][index].samples = [];
                Object.values(event.target.files).forEach(element => {
                    this[uploadTo][index].samples.push(element);
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    public async fetchDocumentAndFilesInFileHistory(documentId, fileHistoryId) {
        try {
            this.$store.state.wemloLoader = true;
            let response = await Axios.post(
                BASE_API_URL + "wemloprocessor/fetchDocumentAndFilesInFileHistory", {
                    documentId: documentId,
                    fileHistoryId: fileHistoryId,
                    loanTxnId: this.$route.query.id
                });
            this.$store.state.wemloLoader = false;
            switch (response.data.statusCode) {
                case 200:
                    this.$refs.dfc["setInitialValuesForShowingSamplesWithHeading"]("View Document", response.data.files);
                    break;
                case 201:
                    this.$refs.dfc["setInitialValues"]([], [], response.data.document, true, this.$route.query.id);
                    break;
                case 202:
                    this.$refs.dfc["setInitialValuesForAttachmentsAndComments"](response.data.rejectComment, response.data.files);
                    break;
                case 500:
                    this.$snotify.error(response.data.message);
                    break;
            }
        } catch (error) {
            this.$store.state.wemloLoader = false;
            console.log(error);
        }
    }

    public changeHasAppovedToSubmitValue(changeFrom, i) { //Has to remove
        try {
            let value = this[changeFrom][i];
            value.hasApprovedToSubmit = value.hasOwnProperty("hasApprovedToSubmit") ?
                !value.hasApprovedToSubmit :
                true;
            this.$set(this[changeFrom], i, value);
        } catch (error) {
            console.log(error);
        }
    }

    public async validate(ifSave) {
        this.ifSave = ifSave;
        //Not reflecting v-validate on 1st time so duplicacy
        await this.$validator.validateAll();
        return await this.$validator.validateAll();
    }

    functionAfterReviewDocument(docId, check) {
        if (check) this.$emit('functionAfterReviewDocument', docId);
    }

    async mounted() {

        if (this.borrowerCondition.length == 0) this.addBorrowerCondition();
        if (this.thirdPartyCondition.length == 0) this.addThirdPartyCondition();
    }
}
