


import { Component } from "vue-property-decorator";
import AddConditions from "@/views/AddConditions.vue";
import AddComment from "@/views/AddComment.vue";
import { LoanSetup } from "@/models/tasks.model";
import DisplayFiles from "@/views/DisplayDocument.vue";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "@/config";

import CreditAuthorization from "@/views/CreditAuthorizationInTask.vue";
import ChangeAssignedProcessor from "@/views/ChangeAssignedProcessor.vue";
import PipeLineSocketMixin from "@/mixins/PipeLineSocketMixin"
import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";

@Component({
  components: {
    DisplayFiles,
    AddConditions,
    AddComment,
    CreditAuthorization,
    ChangeAssignedProcessor,
  },
})
export default class LoanSetupComponent extends mixins(PipeLineSocketMixin, CommonMixin)  {
  private loanSetupData = new LoanSetup();
  private pipeLineHeaderData = {};
  public loanTxnId = null;
  public taskNameForRoom = 'loan-setup';

//Playing learn more video 
private playWeLearnVideo(){
    EventBus.$emit('openWeLearn', {taskName: 'Loan Setup' , videoUrl: 'https://wemlo.s3.amazonaws.com/Common/taskdemo/LoanSetup.mp4'})
}

  public get isSkipProcessingTaskFromLoanSetupToFunding () {
    return this.$store.state.sessionObject.userInfo?.entitlements?.skipProcessingTaskFromLoanSetupToFunding;
  }

  async fetchLoanSetupDetails() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/fetchLoanSetupDetails",
        { loanTxnId: this.loanTxnId });
      this.pipeLineHeaderData = response.data.pipeLineHeaderData;
      this.loanSetupData = response.data.loanSetupData;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }

  async submit(ifSave) {
    try {
      /**
       * Validating conditions here
       */
      ifSave = ifSave == "Save" ? true : false;
      let validConditions = await this.$refs.conditions["validate"](ifSave);
      let validComment = await this.$refs.comment["validateComment"](ifSave);
      /**
       * If clicked on Complete then validate all fields otherwise
       * check conditions if not validate either both then return;
       */
      if (
        (ifSave ? false : !(await this.$validator.validateAll())) ||
        !validConditions ||
        !validComment
      )
        return;

      /**
       * Taking Formdata for sending files and data
       */
      this.$store.state.wemloLoader = true;
      let formData = new FormData();
      /**
       * Merging Conditions here
       */
      this.loanSetupData.borrowerCondition.forEach((f, i) => {
        if (f.samples.length > 0 && !f.samples[0].hasOwnProperty("path")) {
          f.samples.forEach(file =>{
              formData.append("borrowerCondition" + i, file)
              }
          );
        }
        return f
      });

      this.loanSetupData.thirdPartyCondition.forEach((f, i) => {
        if (f.samples.length > 0 && !f.samples[0].hasOwnProperty("path")) {
          f.samples.forEach(file =>
            formData.append("thirdPartyCondition" + i, file)
          );
        }
      });
      formData.append(
        "borrowerCondition",
        JSON.stringify(
          this.loanSetupData.borrowerCondition.map(b => {
            const { fileHistory, ...withoutHistory } = b;
            return { ...withoutHistory, samples: []};
          })
        )
      );
      formData.append(
        "thirdPartyCondition",
        JSON.stringify(
          this.loanSetupData.thirdPartyCondition.map(t => {
              const { fileHistory, ...withoutHistory } = t;
              return { ...withoutHistory, samples: []};
          })
        )
      );

      //  Appending LoanTxnId in formData
      formData.append("loanTxnId", this.loanTxnId);
      /**
       * Appending comment data and comment attachments here in formdata
       */
      let commentObj: any = {
        comment: this.loanSetupData.commentData.comment,
        showCommentToBroker: this.loanSetupData.commentData.showCommentToBroker
      };
      if (
        this.loanSetupData.commentData.commentAttachment.length > 0 &&
        !this.loanSetupData.commentData.commentAttachment[0].hasOwnProperty(
          "path"
        )
      )
        this.loanSetupData.commentData.commentAttachment.forEach(file => {
          formData.append("commentAttachment", file);
        });
      else if (this.loanSetupData.commentData.commentAttachment.length > 0)
        commentObj.commentAttachment = this.loanSetupData.commentData.commentAttachment;
      formData.append("commentData", JSON.stringify(commentObj));
      /**
       * Merging the save or complete option here
       */
      formData.append("options", JSON.stringify(this.loanSetupData.options));
      formData.append("ifSave", ifSave);
      /**
       * Merging Credit Authorization Data here
       */
      if (
        this.loanSetupData.creditAuthorization.status == "uploaded" ||
        this.loanSetupData.creditAuthorization.status == "byPassed"
      ) {
        if (
          this.loanSetupData.creditAuthorization.status == "uploaded" &&
          !this.loanSetupData.creditAuthorization.documents[0].hasOwnProperty(
            "path"
          )
        ) {
          this.loanSetupData.creditAuthorization.documents.forEach(file => {
            formData.append("creditAuthorizationDocument", file);
          });
        }
        if (
          this.loanSetupData.creditAuthorization.status == "byPassed" &&
          this.loanSetupData.creditAuthorization.byPassComment.length > 0
        ) {
          formData.append(
            "creditAuthorizationComment",
            JSON.stringify(this.loanSetupData.creditAuthorization.byPassComment)
          );
        }
      }
      /**
       * Finally sending request to server with formdata in body
       */
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/loanSetup",
        formData);
      /**
       * Printing response message here and redirecting to dashboard
       */
      this.$snotify[
        response.data.status == 200
          ? "success"
          : response.data.status == 403
          ? "info"
          : "error"
      ](response.data.message);
      
      if (response.data.status == 200) {
        this.saveEvent();
      }

      await this.fetchLoanSetupDetails();
      EventBus.$emit('closeWeLearn',false); // Closing learn more video modal;

      if (!ifSave) {
        this.$router.push({ path: "dashboard" });
      }
    } catch (error) {
      const infectedFiles = this.checkResponseIfFileInfected(error);
      infectedFiles.forEach(filename => {
        this.removeInfectedFile(filename)
      })
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  removeInfectedFile(filename: string): void {
    // check if borrower conditions contain infected filename
    this.loanSetupData.borrowerCondition.forEach((f,i) => {
      this.filterSamplesIfInfected(f, filename);
    });

    // check if 3rd party conditions contain infected filename
    this.loanSetupData.thirdPartyCondition.forEach((f, i) => {
      this.filterSamplesIfInfected(f, filename);
    });

    // check if comment attachment contains infected filename
    if (
      this.loanSetupData.commentData.commentAttachment.length > 0 &&
      !this.loanSetupData.commentData.commentAttachment[0].hasOwnProperty(
          "path"
      )
    )
    {
      this.loanSetupData.commentData.commentAttachment = this.loanSetupData.commentData.commentAttachment.filter(file => (file.name !== filename));
    }

    // check if credit authorization contains infected filename
    if (
      this.loanSetupData.creditAuthorization.status == "uploaded" ||
      this.loanSetupData.creditAuthorization.status == "byPassed"
    ) {
      if (
        this.loanSetupData.creditAuthorization.status == "uploaded" &&
        !this.loanSetupData.creditAuthorization.documents[0].hasOwnProperty(
            "path"
        )
      ) {
          const isCreditAuthFileInfected = this.loanSetupData.creditAuthorization.documents.find(file => (file.name === filename));
          this.loanSetupData.creditAuthorization.documents = this.loanSetupData.creditAuthorization.documents.filter(file => (file.name !== filename));
          if (isCreditAuthFileInfected) this.loanSetupData.creditAuthorization.status = null;
      }
    }
  }

  updateBypassCreditAuthData(event) {
    // no use
    try {
      this.loanSetupData.bypassCreditAuthorizationComment = event;
      if (
        this.loanSetupData.bypassCreditAuthorizationComment &&
        this.loanSetupData.bypassCreditAuthorizationComment != ""
      )
        this.loanSetupData.isBypassCreditAuthorization = true;
      else this.loanSetupData.isBypassCreditAuthorization = false;
    } catch (error) {
      console.log(error);
    }
  }

  refreshDocumentStatusById(docId) {
    let borrowerOrThirdPartyCondition = this.loanSetupData.borrowerCondition.find(
      d => d.documentId == docId
    )
      ? "borrowerCondition"
      : "thirdPartyCondition";
    let index = this.loanSetupData[borrowerOrThirdPartyCondition].findIndex(
      d => d.documentId == docId
    );
    this.loanSetupData[borrowerOrThirdPartyCondition][
      index
    ].hasApprovedToSubmit = true;
  }

  async forceLoanProcessingToFundingTask() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(BASE_API_URL + "wemloprocessor/forceLoanProcessingToFundingTask", {
        loanTxnId: this.loanTxnId,
      });
      
      this.$router.push(response.data.redirectTo);
      this.$snotify.success(response.data.message);
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  async beforeMount() {
    this.loanTxnId = this.$route.query.id;
    await this.fetchLoanSetupDetails();
  }
}
